import React , {useState} from 'react'
import logo from './images/propic.png';


const AboutMe = () => {
  const [darkMode, setDarkMode] = useState(false);
  return (
    <>
    <div className='flex justify-center'>
        <div className='mx-auto  ' >
            <img src={logo} layout='fill' objectFit="cover" alt="Logo"  />
        </div>
    </div>
    
    <section>
            <div>
                <h3 className='text-3xl py-1 font-extrabold'>Experience:</h3>
                <h4 className='text-md py-2 leading-8 font-semibold text-gray-600'>Full time Software Engineer for <a target="_blank" href="https://www.infosys.com/"><span className='text-blue-600'>Infosys</span>.</a> </h4>
                <h4 className='text-md py-2 leading-8 font-semibold text-gray-600'> Former Software Engineer for  <a target="_blank" href="https://app.algodex.com/about"><span className='text-blue-600'> AlgoDex </span></a></h4>
                <h4 className='text-md py-2 leading-8 font-semibold text-gray-600'>Received Bachelor's Degree from <a target="_blank" href="https://www.bradley.edu/academic/departments/csis/"><span className='text-blue-600'>Bradley University in computer science. </span></a>.</h4>
                <h4 className='text-md py-2 leading-8 font-semibold text-gray-600'>Former Software Engineer Intern for <a target="_blank" href="https://www.wsp.com/en-us/"><span className='text-blue-600'> WSP </span></a> 
                    </h4>
            
            </div>
    </section>
    </>
  )
}

export default AboutMe