import React from 'react'

function Footer() {
  return (
    <div>
        <footer>
            <div class="text-center p-4">
                © 2023 Copyright:
                <a classname=" text-blue-600" href="https://sgii.us/"> <span className='text-blue-600'>SGII</span></a>
                
            </div>
        </footer>
    </div>
  )
}

export default Footer