
import './App.css';
import AboutMe from './components/AboutMe';
import Footer from './components/Footer';
import Frontpage from './components/Frontpage';
import Navbar from './components/Navbar';
import Projects from './components/Projects';

function App() {
  
  return (
    <div className='App' >
      <div className=' '>
      <Navbar/>
      <Frontpage/>
      <AboutMe/>
      <br></br>
      <Projects/>
      <Footer/>
      </div>
     
    </div>
  );
}

export default App;
