import React  from 'react'
import SgEcomm from './images/ecomm.png';
import SocialClone from './images/blog.ico';
import ExpenseApp from './images/expensive.ico';
import cryptoApp from './images/SGCryptologo.png'
import TaskApp from './images/task.png'
import TextApp from './images/texticon.png'
import CryptoPriceApp from './images/blockchain.ico'
import YTApp from './images/downloader.ico'
import sgbank from './images/sgbank.png'

const Projects = () => {
  return (
    <>
       <section>
          <div>
            <h3 className='text-3xl py-1 font-extrabold'>My Portfolio:</h3>
            
            <p className="text-md font-semibold  py-2 leading-8 text-gray-800 dark:text-gray-200">
              These below are applications/ software I created from sctatch and on on my own.
            </p>
          </div>

          <div className="lg:flex gap-10">

            <div className="text-center shadow-lg p-10 rounded-xl my-10  dark:bg-white flex-1">
              <div className='flex justify-center'><img src={sgbank} width={100} height={100} alt="Logo"  /></div>
              <h3 className="text-lg font-medium pt-8 pb-2  ">
              SG Bank Calculator 
              </h3>
              <div className='flex justify-center'>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://twitter.com/Almightysanti17/status/1622340969839329283'>Video Showcase</a>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://sgbankcalculator.com/'> Website Link</a>
               
              </div>
              <p className="py-2">
              This website is able to web scrape the live price of cryptocurrency called BANK, and calculate the rewards for users who participate in liquidity pools of the farms in Algofi. 
              </p>
              <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
              <p className="text-gray-800 py-1 font-semibold">HTML</p>
              <p className="text-gray-800 py-1 font-semibold">CSS</p>
              <p className="text-gray-800 py-1 font-semibold">Bootstrap</p>
              <p className="text-gray-800 py-1 font-semibold">Javascript</p>
              
            </div>

            <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
                  <div className='flex justify-center'><img src={CryptoPriceApp} width={100} height={100}   alt="Logo"  /></div>
                <h3 className="text-lg font-medium pt-8 pb-2 ">Crypto Price App</h3>
                <div className='flex justify-center'>
                  <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/17XuUJuXlyZIvGUlXJOvC95iTStpPTXwA/view?usp=sharing'>Video Showcase</a>
                  <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='##'>Source Code</a>
                </div>
                <p className="py-2">
                This is an app tells you a specfic cryptocurrency price in USD depending on what the user inputs. It scrapes the price data off cryptocompare API. 
                </p>
                <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
                <p className="text-gray-800 py-1 font-semibold">Python</p>
                <p className="text-gray-800 py-1 font-semibold">Tkinter</p>
            
                
              
                
              </div>



              <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
                  <div className='flex justify-center'><img src={YTApp} width={100} height={100}   alt="Logo"  /></div>
                <h3 className="text-lg font-medium pt-8 pb-2 ">SG Downloader App</h3>
                <div className='flex justify-center'>
                  <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/1_DohaWDkSa_9ZSgO3v_bv55OOJJ42F70/view?usp=sharing'>Video Showcase</a>
                  <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='##'>Source Code</a>
                </div>
                <p className="py-2">
                This app allows users to download any youtube video by inputting a link and file location of where to download the video. 
                </p>
                <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
                <p className="text-gray-800 py-1 font-semibold">Python</p>
                <p className="text-gray-800 py-1 font-semibold">Tkinter</p>
            
                
              
                
              </div>
            
           
            


          

           
          </div>

          <div className='lg:flex gap-10'>

                
            <div className="text-center shadow-lg p-10 rounded-xl my-10  dark:bg-white flex-1">
              <div className='flex justify-center'><img src={TaskApp} width={100} height={100} alt="Logo"  /></div>
              <h3 className="text-lg font-medium pt-8 pb-2  ">
              Task Tracker Website
              </h3>
              <div className='flex justify-center'>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/1B6UYvIfkRJ5Wnl693C9a_foIkpONW7__/view?usp=sharing'>Video Showcase</a>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://github.com/sangar3/TaskTrackerApp/tree/main/my-app'>Source Code</a>
               
              </div>
              <p className="py-2">
              A website to track all of your tasks in the upcoming days.
              </p>
              <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
              <p className="text-gray-800 py-1 font-semibold">React JS</p>
              <p className="text-gray-800 py-1 font-semibold">HTML</p>
              <p className="text-gray-800 py-1 font-semibold">CSS</p>
              <p className="text-gray-800 py-1 font-semibold">Javascript</p>
              
            </div>

             <div className="text-center shadow-lg p-10 rounded-xl my-10  dark:bg-white flex-1">
              <div className='flex justify-center'><img src={cryptoApp} width={100} height={100} alt="Logo"  /></div>
              <h3 className="text-lg font-medium pt-8 pb-2  ">
              SG Cryptocurrency Price Tracker Website 
              </h3>
              <div className='flex justify-center'>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/1MpYUFK-Ap0RrK7tx41-WVO_jIAYR3nzE/view?usp=sharing'>Video Showcase</a>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://sgii-cryptocurrency-pricetracker.netlify.app/'>Website Link</a>
               
              </div>
              <p className="py-2">
              This is a cryptocurrency price tracker website and uses a coin gecko API was called to configure each piece of imperative data to display on the website.
              </p>
              <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
              <p className="text-gray-800 py-1 font-semibold">React JS</p>
              <p className="text-gray-800 py-1 font-semibold">HTML</p>
              <p className="text-gray-800 py-1 font-semibold">CSS</p>
              <p className="text-gray-800 py-1 font-semibold">Javascript</p>
              
            </div>


            <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
              <div className='flex justify-center'><img src={SgEcomm} width={100} height={100} alt="Logo"  /></div>
              <h3 className="text-lg font-medium pt-8 pb-2 ">
              SG Ecomm Website 
              </h3>
              <div className='flex justify-center'>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/1jmXdt1v-yMVQO6NLQp0D8lOBeU9n4IEu/view?usp=sharing'>Video Showcase</a>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://sgecomm-sangar3.vercel.app/'>Website Link</a>
                
              </div>
              <p className="py-2">
              This is a fully functional ecommerce website, it uses fake items from a fake store API.
              </p>
              
              <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
              <p className="text-gray-800 py-1 font-semibold">React JS</p>
               <p className="text-gray-800 py-1 font-semibold">HTML</p>
              <p className="text-gray-800 py-1 font-semibold">Tailwind CSS</p>
              <p className="text-gray-800 py-1 font-semibold">Javascript</p>

             
           
            </div>

            

          </div>



          <div className="lg:flex gap-10">

            
          <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
                <div className='flex justify-center'><img src={SocialClone} width={100} height={100}   alt="Logo"  /></div>
              <h3 className="text-lg font-medium pt-8 pb-2 ">Blog Clone Website</h3>
              <div className='flex justify-center'>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/1BPS8Aa7nW1_uKm_uDko9Hhgj1yZ8Tz-U/view?usp=sharing'>Video Showcase</a>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://github.com/sangar3/BlogWebsite'>Source Code</a>
              </div>
              <p className="py-2">
              A simple blog website to make posts and add comments.
              </p>
              <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
              <p className="text-gray-800 py-1 font-semibold">Python </p>
              <p className="text-gray-800 py-1 font-semibold"> Django</p>
              <p className="text-gray-800 py-1 font-semibold">HTML</p>
              <p className="text-gray-800 py-1 font-semibold">CSS</p>
              
              
            
              
            </div>
          


            <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
              <div className='flex justify-center'><img src={TextApp} width={100} height={100} alt="Logo"  /></div>
              <h3 className="text-lg font-medium pt-8 pb-2 ">
              Text Editor App
              </h3>
              <div className='flex justify-center'>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/1_yalUpMm3ammIczn49iPT-m2VHbuAvT-/view?usp=sharing'>Video Showcase</a>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://github.com/sangar3/Santi-s-Text-Editor'>Source Code</a>
                
              </div>
              <p className="py-2">
              This is a simple text editor app that write, save, and copy you write. 
              </p>
              
              <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
              <p className="text-gray-800 py-1 font-semibold">Python</p>
              <p className="text-gray-800 py-1 font-semibold">Tkinter</p>
              

             
           
            </div>
            

            <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
                <div className='flex justify-center'><img src={ExpenseApp} width={100} height={100}   alt="Logo"  /></div>
              <h3 className="text-lg font-medium pt-8 pb-2 ">SGExpenses App</h3>
              <div className='flex justify-center'>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://drive.google.com/file/d/1FmQC3XXkOMPhlWSX2q2YUY2coo7VRHkF/view?usp=sharing'>Video Showcase</a>
                <a className='bg-gradient-to-r from-red-300 to-red-600 text-white px-4 py-2 rounded-md ml-8' href='https://github.com/sangar3/ExpensesApp'>Source Code</a>
              </div>
              <p className="py-2">
              This is an expense app, The purpose is to track all of your assets you buy or spend on during the week
              </p>
              <h4 className="py-4 text-blue-600 font-bold">Tech Stack:</h4>
              <p className="text-gray-800 py-1 font-semibold">Flutter</p>
          
              
            
              
            </div>


            

            
            

           
          </div>


          

        </section>
    </>
  )
}

export default Projects