import React, {useState} from 'react'
import {
    AiFillTwitterCircle,
    AiFillLinkedin,
    AiFillGithub,
    AiFillfiverr,
  } from "react-icons/ai";
const Frontpage = () => {
    const [darkMode, setDarkMode] = useState(false);
  return (
    <>
        <div className='text-center p-10'>
            <h2 className='font-bold  text-5xl py-2 text-blue-600 font-medium'>Santiago Garcia II </h2>
            <h3 className=' font-bold text-2xl py-2'>Software Engineer</h3>
            <p className='font-bold  text-md py-5 leading-8 text-gray-800'>Based in Chicago. I love coding, sports, and my family.</p>
        </div>

        <div className='text-5xl flex justify-center gap-16 py-3 text-gray-600'> 
            
            <a target="_blank" href="https://github.com/sangar3"><AiFillGithub/></a>
            
            <a target="_blank" href="https://twitter.com/almightysanti17?lang=en"><AiFillTwitterCircle/></a>
           
            <a target="_blank"  href="https://www.linkedin.com/in/santiago-garcia-ii-84a64a197/" > <AiFillLinkedin/></a>

            
        </div>

        <div>

        </div>
        
    </>
   
  )
}

export default Frontpage