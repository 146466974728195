import React, {useState} from 'react'
import {BsFillMoonStarsFill} from 'react-icons/bs'; 

const Navbar = () => {
    
const [darkMode, setDarkMode] = useState(false);
  return (
    
    <div className={darkMode ? 'dark' : ""}>
        <main className='bg-blue-300 px-10  dark:bg-gray-900 md:px-20 lg:px-40 '>
            <section className='min-h screen'>
                <nav className='py-10 mb-12 flex justify-between '>
                    <h1 className='text-xl font-extrabold'>Santiago Garcia II </h1>
                    <ul className='flex items-center'>
                        <li>
                            <a className=' font-extrabold bg-blue-600 text-white px-7 py-5 rounded-md ml-8' href='https://docs.google.com/document/d/1V14QdsgJ1oK1I9XKhsZ8PpwFJSiD9JFS/edit?usp=sharing&ouid=112872962213360573946&rtpof=true&sd=true'>Resume</a>
                        </li>
                    </ul>
                </nav>
            </section>
      </main>
    </div>
  )
}

export default Navbar